import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="error">
      <h1 className="error__title">Ué, cadê? :(</h1>
      <p className="error__description">Hmm, talvez que a página que você procura não existe mais ou está com algum problema.</p>
      <Link aria-label="Voltar para Home" to="/" className="error__link">Voltar para a home</Link>
    </section>
  </Layout>
);

export default NotFoundPage
